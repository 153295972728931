@import '../variables'

body > footer
  background: linear-gradient(-165deg, $color-blue, $color-light-blue 40%, $color-light-blue 60%, $color-blue)
  color: $color-white
  padding: 2em 1em

  > address
    > a, > div
      display: block
      color: $color-white
      margin-bottom: 1em
      font-size: $font-size-large
      font-weight: $font-weight-bold

      > i
        margin-right: 1em

  > label
    display: block
    text-align: center
    margin: 0
    font-size: $font-size-extra-large
    font-weight: $font-weight-bold

  > p
    color: $color-white
    font-size: $font-size-large
    font-weight: $font-weight-bold

@media only screen and (min-width: $breakpoint-desktop)
  body > footer
    display: grid
    grid-template-columns: 1fr 1fr
    gap: 2em
    padding: 2em 5em

    > p
      align-self: center
      text-align: right

    > label
      grid-column: 1 / span 2