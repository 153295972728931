@import '../variables'

.x-loading
  background: linear-gradient(-270deg, $color-light-gray, $color-light-blue)
  background-size: 200%
  animation: bg-position-keyframes 1s ease-out infinite

.x-loading-progress
  text-align: center
  color: white

@keyframes bg-position-keyframes
  0%
    background-position-x: 100%
  100%
    background-position-x: -100%