@import '../variables'

#payments-container
  > ul
    margin-left: 1.5em

  > #new-payment-container
    display: grid
    grid-template-columns: 1fr 1fr
    column-gap: 1em
    row-gap: 0.3em

#declarations-container
  display: grid
  grid-template-columns: 1fr 1fr
  gap: 1em

  > .table
    grid-column: 1 / span 2

    > .header > .cell
      white-space: nowrap
      text-overflow: ellipsis
      overflow: hidden
      text-align: center

      > .full
        display: none

      > .short
        display: inline-block

    > .row > .cell
      text-align: center

      button.app-btn
        background-color: $color-white
        color: $color-blue
        padding: 0

        > i
          margin-right: 0

        > label
          display: none

#declaration-view
  > #print-container
    overflow-x: auto

  > #print-btn
    margin: 0.5em auto 0 auto
    display: block

#new-declaration-form
  display: grid
  grid-template-columns: 1fr 1fr auto
  gap: 0.5em

  > *
    grid-column: 1 / span 2

  > #sickFrom1-container, > #sickFrom2-container, > #maternityFrom-container
    grid-column: 1 / span 1

  > #sickTo1-container, > #sickTo2-container, > #maternityTo-container
    grid-column: 2 / span 1

  > h4
    margin: 0

  > .app-hint
    grid-column: 3
    align-self: center

@media only screen and (min-width: $breakpoint-desktop)
  #payments-container
    > ul
      margin-left: 2.5em

    > #new-payment-container
      grid-template-columns: 1fr 1fr 1fr 1fr

  #declaration-view > #print-btn
    margin: 2em auto 0 auto

  #declarations-container
    width: max-content

    > .table
      > .header > .cell
        > .full
          display: inline-block

        > .short
          display: none

      > .row > .cell
        button.app-btn
          background-color: $color-light-blue
          color: $color-white
          padding: 0.75em

          > i
            margin-right: 0.5em

          > label
            display: inline-block