@import '../variables'
@import '~/node_modules/flatpickr/dist/flatpickr'
@import '~/node_modules/flatpickr/dist/plugins/monthSelect/style'

#overlay
  position: fixed
  top: 0
  left: 0
  right: 0
  bottom: 0
  background-color: $color-light-gray-semitransparent
  z-index: 101

  > #overlay-container
    position: absolute
    top: 50%
    left: 0.5em
    right: 0.5em
    transform: translateY(-50%)
    max-height: 95vh
    overflow: auto
    display: flex
    flex-direction: column

    > #overlay-header
      position: relative

      > h2
        background: linear-gradient(-135deg, $color-blue, $color-light-blue 20%, $color-light-blue 80%, $color-blue), $color-light-blue
        color: $color-white
        margin: 0
        padding: 0.5em
        padding-right: 2em
        border-radius: 0.5em 0.5em 0 0
        border-bottom: 0.1em solid $color-orange
        max-width: 100%
        text-overflow: ellipsis
        white-space: nowrap
        overflow: hidden

      > #close
        position: absolute
        top: 0
        right: 0
        cursor: pointer
        color: $color-white
        padding: 1.2em
        font-size: initial

    > #overlay-content
      padding: 0.5em
      border: 0.1em solid $color-blue
      border-top: none
      border-radius: 0 0 0.5em 0.5em
      background-color: $color-white
      flex-grow: 1
      overflow: auto

.hidden
  display: none

.disable-scroll
  overflow: hidden

.input-container
  > label
    display: block
    color: $color-black
    font-size: $font-size-normal
    font-weight: $font-weight-normal

    > i
      color: $color-coral

  > input
    width: 100%
    padding: 0.5em
    border-radius: 1em
    border: 0.1em solid $color-black

.select-container
  > label
    display: block
    color: $color-black
    font-size: $font-size-normal
    font-weight: $font-weight-normal

  > select
    width: 100%
    padding: 0.5em
    border-radius: 1em
    border: 0.1em solid $color-black

button.app-btn
  padding: 0.75em
  border-radius: 0.5em
  border: none
  color: $color-white
  font-weight: $font-weight-bold
  background-color: $color-light-blue
  cursor: pointer
  white-space: nowrap

  > i
    margin-right: 0.5em

a.app-lnk
  color: $color-orange
  cursor: pointer

label.app-lbl
  margin: 0.3em
  display: block

  &.warn
    color: $color-coral
    font-size: $font-size-normal

  &.success
    color: $color-green
    font-weight: $font-weight-semi-bold

form.app-form
  display: grid
  gap: 1em
  min-width: 275px

  > h2
    margin: 0

h1, h2, h3, h4, h5, h6
  color: $color-light-blue
  border-left: 0.3em solid $color-orange
  padding-left: 1em

.table
  display: table
  width: 100%
  border-collapse: collapse

  > .header
    display: table-header-group
    font-weight: $font-weight-bold
    background: linear-gradient(-135deg, $color-blue, $color-light-blue 20%, $color-light-blue 80%, $color-blue), $color-light-blue
    color: $color-white

    > .cell
      border: 0.1em solid $color-light-blue
      border-bottom: 0.1em solid $color-orange

  > .row
    display: table-row

    > .cell
      border: 0.1em solid $color-light-gray
      font-weight: $font-weight-semi-bold
      color: $color-black

  > .header, > .row
    > .cell
      display: table-cell
      padding: 0.5em
      font-size: $font-size-normal

.toggle
  position: relative
  display: inline-block
  width: 50px
  height: 20px

  > input
    opacity: 0
    width: 0
    height: 0

    &:checked + .slider
      background-color: $color-green

      &:before
        transform: translateX(30px)

    &:focus + .slider
      box-shadow: 0 0 1px #2196F3

  > .slider
    position: absolute
    cursor: pointer
    top: 0
    left: 0
    right: 0
    bottom: 0
    background-color: $color-light-gray
    transition: .4s
    border-radius: 20px

    &:before
      position: absolute
      content: ''
      height: 16px
      width: 16px
      left: 2px
      bottom: 2px
      background-color: $color-white
      -webkit-transition: .4s
      transition: .4s
      border-radius: 50%

.app-hint
  grid-column: 3
  align-self: center
  position: relative

  > .hint-icon
    cursor: help

  > .hint-content
    position: absolute
    top: 3em
    right: 0
    width: 20em
    background-color: $color-white
    border: 0.1em solid $color-light-blue
    border-radius: 0.5em
    padding: 0.5em
    opacity: 0
    pointer-events: none
    font-size: $font-size-normal
    z-index: 103

  &:hover, &.displayed
    > .hint-content
      opacity: 1

.grid-2
  display: grid
  grid-template-columns: auto auto
  column-gap: 1em
  row-gap: 0.3em
  align-items: center

@media only screen and (min-width: $breakpoint-desktop)
  #overlay > #overlay-container
    top: 50%
    left: 50%
    right: unset
    max-width: 90vw
    transform: translate(-50%, -50%)

    > #overlay-content
      padding: 2em 3em

  .table
    > .header, > .row
      > .cell
        padding: 1em
        font-size: $font-size-large