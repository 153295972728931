$color-black: #36454F
$color-light-gray: #c4c4c4
$color-white: #fff
$color-blue: #147
$color-light-blue: #369
$color-pale-blue: #3973ac
$color-orange: #f60
$color-coral: #f44030
$color-green: #00cc00
$color-transparent: transparent
$color-light-gray-semitransparent: #c4c4c488

$font-size-normal: 0.8em
$font-size-large: 1em
$font-size-larger: 1.25em
$font-size-extra-large: 1.5em
$font-size-huge: 3em

$font-weight-normal: 500
$font-weight-semi-bold: 600
$font-weight-bold: 800

$breakpoint-desktop: 1024px
$breakpoint-desktop-big: 1400px