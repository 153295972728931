@import '../variables'
@import '../core/profile'
@import '../core/declarations'

body > header
  position: fixed
  top: 0
  left: 0
  right: 0
  z-index: 100
  background-color: $color-pale-blue
  border-bottom: 0.1em solid $color-orange
  box-shadow: 0px 10px 13px -7px $color-light-gray

  > nav
    ul
      list-style: none
      padding: 0
      margin: 0

      > :last-child
        border-bottom: none

      > li
        display: none
        border-bottom: 0.1em solid $color-light-gray
        background: linear-gradient(-135deg, $color-blue, $color-pale-blue 25%, $color-pale-blue 75%, $color-blue)

        > a, > label
          display: block
          font-size: $font-size-large
          line-height: $font-size-large
          font-weight: $font-weight-bold
          padding: 1em 0
          text-decoration: none
          text-align: center
          cursor: pointer
          color: $color-white

          > i
            margin-right: 1em

        &.active
          display: block
          cursor: not-allowed

          > a
            color: $color-white
            pointer-events: none

    > #mobile-menu
      position: fixed
      top: 0
      right: 0
      font-size: $font-size-large
      color: $color-white
      padding: 1em

      &:before
        content: '\f0c9'

  &.expanded
    > nav
      ul > li
        display: block

      > #mobile-menu:before
        content: '\f00d'

@media only screen and (min-width: $breakpoint-desktop)
  body > header > nav
    ul
      display: flex
      justify-content: space-evenly

      > li
        display: block
        flex: 1
        border-bottom: 0.2em solid $color-pale-blue
        border-radius: 0.2em
        background: $color-pale-blue

        &.active
          border-bottom-color: $color-orange

        > ul
          justify-content: center

          > li
            flex: 0
            cursor: pointer

            > label
              width: 1.5em
              overflow-x: hidden
              white-space: nowrap
              transition: 0.5s width ease-out

            &:hover
              > label
                width: 10em
                text-align: left

            &:nth-child(n+2)
              margin-left: 1em

    > #mobile-menu
      display: none