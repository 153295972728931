@import '../../variables'
@import '../../partials/header'
@import '../../partials/footer'
@import '../../core/components'
@import '../../core/executable'

*
  box-sizing: border-box

body, a, p
  font-family: Arial, Helvetica, sans-serif
  color: $color-black
  font-weight: $font-weight-normal

body
  margin: 0

  > main > article
    padding: 3.5em 0.5em 1em 0.5em

    > section
      margin-top: 1em
      border-bottom: 0.1em solid $color-light-gray

      &:last-child
        border-bottom: none

@media only screen and (min-width: $breakpoint-desktop)
  body
    &.rtl > main
      background: linear-gradient(135deg, $color-light-gray, $color-transparent 20%, $color-transparent 80%, $color-light-gray)

    &.ltr > main
      background: linear-gradient(45deg, $color-light-gray, $color-transparent 20%, $color-transparent 80%, $color-light-gray)

    > main > article
      background-color: $color-white
      width: 80%
      margin: 0 auto
      padding: 3.5em 1em 3em 1em
      box-shadow: 0px 0px 10px 0px $color-light-gray

      > section
        margin-top: 3em

@media only screen and (min-width: $breakpoint-desktop-big)
  body
    background-repeat: no-repeat

    &.rtl
      background-position: right 10em

    &.ltr
      background-position: left 10em

    > main > article
      width: 60%